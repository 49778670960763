export const PREFIX_KEY = 'dashboard_rd_';

export const LS_TOKEN_KEY = PREFIX_KEY + 'token_key';
export const LS_REFRESH_TOKEN_KEY = PREFIX_KEY +  'refresh_token_key';
export const LS_USER_KEY = PREFIX_KEY +  'user_key';

// Chiamate APIs per il LOGIN
export const BASE_URL_API = "https://dashboard-rd-api.neumann.abinsula.com/";
export const URL_API_USER_LOGIN = BASE_URL_API + "users/auth_token/";

// Chiamate APIs per dashboardUser
export const URL_API_DASHBOARD_DETAIL = BASE_URL_API + "project/detail-dashboard"
export const URL_API_DASHBOARD_DETAIL_COMPANY = BASE_URL_API + "project/detail-company?company="
export const URL_API_DASHBOARD_DETAIL_TYPE = BASE_URL_API + "project/detail-type?type="
export const URL_API_DASHBOARD_USER_TYPE = BASE_URL_API + "project/type-dashboard"
export const URL_API_DASHBOARD_USER_EXPENSE_IN_YEAR = BASE_URL_API + "project/expense-in-year"
export const URL_API_DASHBOARD_USER_COST = BASE_URL_API + "project/cost-dashboard"
export const URL_API_DASHBOARD_USER_MAX = BASE_URL_API + "project/max-value"
export const URL_API_DASHBOARD_USER_DEADLINE = BASE_URL_API + "project/next-deadline"
export const URL_API_DASHBOARD_USER_COMPANY = BASE_URL_API + "project/list-company"
export const URL_API_DASHBOARD_DEADLINE_PROJECTS = BASE_URL_API + "project/deadline"

// chiamate APIs per SideMenuDashboard
export const URL_API_COMPANY_LIST = BASE_URL_API + "company"

// Chiamate APIS per Projects
export const URL_API_PROJECTS_LIST = BASE_URL_API + "project"
export const URL_API_ALL_PROJECTS_LIST = BASE_URL_API + "project/get-all-projects"
/*export const URL_API_GET_PROJECT_RETRIEVE = BASE_URL_API + "project"*/

// Chiamate APIs per Project ---> Milestone
export const URL_API_ALL_MILESTONES = BASE_URL_API + "milestone/get-all-milestones"
// Chiamate APIs per Project ---> Budget
export const URL_API_EXPENSE_AREA_AGGREGATE = BASE_URL_API + "expense-area/amount-aggregate"
export const URL_API_EXPENSE_ITEM_AGGREGATE = BASE_URL_API + "expense-item/amount-aggregate"
export const URL_API_EXPENSE_DETAIL_AGGREGATE = BASE_URL_API + "expense-detail/amount-aggregate"

// Chiamate APIs per Project ---> Amortization
export const URL_API_AMORTIZATION = BASE_URL_API + "amortization"
export const URL_API_AMORTIZATION_TABLE = BASE_URL_API + "amortization/get-table-records"
export const URL_API_AMORTIZATION_DETAIL = BASE_URL_API + "amortization/detailed"

// Chiamate APIs per Project ---> Expense
export const URL_API_EXPENSE = BASE_URL_API + "expense"

// Chiamate APIs per Project ---> Contribution
export const URL_API_CONTRIBUTION = BASE_URL_API + "contribution"

// Chiamate APIs per Edit vari
export const URL_API_GET_AREAS = BASE_URL_API + "expense-area"
export const URL_API_GET_ITEMS = BASE_URL_API + "expense-item"
export const URL_API_GET_DETAILS = BASE_URL_API + "expense-detail"
export const URL_API_GET_BUDGETRECORD = BASE_URL_API + "budget-record"
export const URL_API_GET_BUDGETRECORD_DETAILED = BASE_URL_API + "budget-record/detailed"
export const URL_API_MILESTONE_LIST = BASE_URL_API + "milestone"
export const URL_API_GET_PAGINATION_RECORD = BASE_URL_API + "budget-record/get-table-records"
export const URL_API_GET_EMPLOYEE = BASE_URL_API + "employee"
export const URL_API_GET_ASSIGNMENT = BASE_URL_API + "assignment"
export const URL_API_GET_WOASSIGNMENT = BASE_URL_API + "woassignment"
export const URL_API_GET_PAGINATION_CONTRIBUTION = BASE_URL_API + "contribution/get-table-records"
export const URL_API_EXPENSE_RETRIEVE_DETAILED = BASE_URL_API + "expense/detailed"

// APIs per SPESA EFFETTIVA per Area, Item e Detail
export const URL_API_REAL_EXPENSE_AREA = BASE_URL_API + "expense/get-sum-expense-real-area"
export const URL_API_REAL_EXPENSE_ITEM = BASE_URL_API + "expense/get-sum-expense-real-item"
export const URL_API_REAL_EXPENSE_DETAIL = BASE_URL_API + "expense/get-sum-expense-real-detail"


