// @ts-check

/**
 * @typedef {import('vue-router').RouteRecordRaw} RouteRecordRaw
 */

import { createRouter, createWebHistory } from 'vue-router';
import { StorageHelper } from "@/common/storageHelper";

/**
 * @type {Array<RouteRecordRaw>}
 */
const routes = [
    {
        path: "/login",
        name: "login",
        component: () => import("../views/Login.vue"),
    },
    {
        path: "/",
        name: "dashboardUser",
        meta: { requiresAuth: true },
        component: () => import("../views/DashboardUser.vue"),
    },
    {
        path: "/project",
        name: "projectSelect",
        meta: { requiresAuth: true},
        component: () => import("../views/ProjectSelect.vue"),
    },
    {
        path: "/information",
        name: "informationPage",
        meta: { requiresAuth: true},
        component: () => import("../views/InformationPage.vue"),
    },
    {
        path: "/company/:companyId",
        name: "dashboardCompany",
        meta: { requiresAuth: true},
        component: () => import("../views/DashboardCompany.vue"),
        props: true,
    },
    {
        path: "/type-financing/:typeFinancing",
        name: "dashboardType",
        meta: { requiresAuth: true},
        component: () => import("../views/DashboardType.vue"),
        props: true,
    },
    {
        path: "/deadline",
        name: "dashboardUserDeadline",
        meta: { requiresAuth: true},
        component: () => import("../views/DashboardUserDeadline.vue"),
    },
    {
        path: "/progetto/:projectId",
        name: "dettaglio-progetto",
        meta: { requiresAuth: true},
        component: () => import("../views/DashboardProjectHome.vue"),
        props: true,
    },
    {
        path: "/progetto/:projectId/milestones",
        name: "projectMilestone",
        meta: { requiresAuth: true},
        component: () => import("../views/DashboardProjectMilestone.vue"),
        props: true,
    },
    {
        path: "/progetto/:projectId/budget",
        name: "projectBudget",
        meta: { requiresAuth: true},
        component: () => import("../views/DashboardProjectBudget.vue"),
        props: true,
    },
    {
        path: "/progetto/:projectId/assegnazioni",
        name: "projectAssignment",
        meta: { requiresAuth: true},
        component: () => import("../views/DashboardProjectAssignment.vue"),
        props: true,
    },
    {
        path: "/progetto/:projectId/ammortamenti",
        name: "projectAmortization",
        meta: { requiresAuth: true},
        component: () => import("../views/DashboardProjectAmortization.vue"),
        props: true,
    },
    {
        path: "/progetto/:projectId/spese",
        name: "projectExpense",
        meta: { requiresAuth: true},
        component: () => import("../views/DashboardProjectExpense.vue"),
        props: true,
    },
    {
        path: "/progetto/:projectId/contributi",
        name: "projectContribution",
        meta: { requiresAuth: true},
        component: () => import("../views/DashboardProjectContribution.vue"),
        props: true,
    },
    {
        path: "/impostazioni",
        name: "settingDashboard",
        meta: { requiresAuth: true},
        component: () => import("../views/DashboardSettings.vue"),
    },
    {
        path: "/progetto/:projectId/edit",
        name: "projectEdit",
        meta: { requiresAuth: true},
        component: () => import("../views/project/home/ProjectHomeEdit.vue"),
        props: true,
    },
    {
        path: "/progetto/new",
        name: "nuovo-progetto",
        meta: { requiresAuth: true},
        component: () => import("../views/project/home/ProjectHomeNew.vue"),
    },
    {
        path: "/progetto/:projectId/milestones/edit",
        name: "milestonesEdit",
        meta: { requiresAuth: true},
        component: () => import("../views/project/milestone/ProjectMilestoneEdit.vue"),
        props: true,
    },
    {
        path: "/progetto/:projectId/milestone/:milestoneId/edit",
        name: "editSingleMilestone",
        meta: { requiresAuth: true},
        component: () => import("../views/project/milestone/ProjectSingleMilestoneEdit.vue"),
        props: true,
    },
    {
        path: "/progetto/:projectId/new-milestone",
        name: "newMilestone",
        meta: { requiresAuth: true},
        component: () => import("../views/project/milestone/ProjectMilestoneNew.vue"),
        props: true,
    },
    {
        path: "/progetto/:projectId/new-budget-record",
        name: "newBudgetRecord",
        meta: { requiresAuth: true},
        component: () => import("../views/project/budget/ProjectBudgetNew.vue"),
        props: true,
    },
    {
        path: "/progetto/:projectId/budgets-record/edit",
        name: "budgetsEdit",
        meta: { requiresAuth: true},
        component: () => import("../views/project/budget/ProjectBudgetEdit.vue"),
        props: true,
    },
    {
        path: "/progetto/:projectId/budget-record/:budgetId/edit",
        name: "editSingleBudget",
        meta: { requiresAuth: true},
        component: () => import("../views/project/budget/ProjectSingleBudgetEdit.vue"),
        props: true,
    },
    {
        path: "/progetto/:projectId/new-amortization",
        name: "newAmortization",
        meta: { requiresAuth: true},
        component: () => import("../views/project/amortization/ProjectAmortizationNew.vue"),
        props: true,
    },
    {
        path: "/progetto/:projectId/amortization/edit",
        name: "amortizationsEdit",
        meta: { requiresAuth: true},
        component: () => import("../views/project/amortization/ProjectAmortizationEdit.vue"),
        props: true,
    },
    {
        path: "/progetto/:projectId/amortization/:amortizationId/edit",
        name: "editSingleAmortization",
        meta: { requiresAuth: true},
        component: () => import("../views/project/amortization/ProjectSingleAmortizationEdit.vue"),
        props: true,
    },
    {
        path: "/progetto/:projectId/new-assignment",
        name: "newAssignment",
        meta: { requiresAuth: true},
        component: () => import("../views/project/assignment/ProjectAssignmentNew.vue"),
        props: true,
    },
    {
        path: "/progetto/:projectId/assignment/edit",
        name: "assignmentsEdit",
        meta: { requiresAuth: true},
        component: () => import("../views/project/assignment/ProjectAssignmentEdit.vue"),
        props: true,
    },
    {
        path: "/progetto/:projectId/assignment/:assignmentId/edit",
        name: "editSingleAssignment",
        meta: { requiresAuth: true},
        component: () => import("../views/project/assignment/ProjectSingleAssignmentEdit.vue"),
        props: true,
    },
    {
        path: "/progetto/:projectId/woassignment/:woassignmentId/edit",
        name: "editSingleWOAssignment",
        meta: { requiresAuth: true},
        component: () => import("../views/project/assignment/ProjectSingleWOAssignmentEdit.vue"),
        props: true,
    },
    {
        path: "/progetto/:projectId/new-contribution",
        name: "newContribution",
        meta: { requiresAuth: true},
        component: () => import("../views/project/contribution/ProjectContributionNew.vue"),
        props: true,
    },
    {
        path: "/progetto/:projectId/contribution/edit",
        name: "contributionsEdit",
        meta: { requiresAuth: true},
        component: () => import("../views/project/contribution/ProjectContributionEdit.vue"),
        props: true,
    },
    {
        path: "/progetto/:projectId/contribution/:contributionId/edit",
        name: "editSingleContribution",
        meta: { requiresAuth: true},
        component: () => import("../views/project/contribution/ProjectSingleContributionEdit.vue"),
        props: true,
    },
    {
        path: "/progetto/:projectId/new-expense",
        name: "newExpense",
        meta: { requiresAuth: true},
        component: () => import("../views/project/expense/ProjectExpenseNew.vue"),
        props: true,
    },
    {
        path: "/progetto/:projectId/expense/edit",
        name: "contributionsEdit",
        meta: { requiresAuth: true},
        component: () => import("../views/project/expense/ProjectExpenseEdit.vue"),
        props: true,
    },
    {
        path: "/progetto/:projectId/expense/:expenseId/edit",
        name: "editSingleExpense",
        meta: { requiresAuth: true},
        component: () => import("../views/project/expense/ProjectSingleExpenseEdit.vue"),
        props: true,
    },
];

/**
 * @type {import('vue-router').Router}
 */
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    const isAuthenticated = StorageHelper.getToken();

    if (to.meta.requiresAuth && !isAuthenticated) {
        next({ name: "login" });
    } else if (!to.meta.requiresAuth && isAuthenticated && to.name === "login") {
        next({ name: "dashboardUser" });
    } else {
        next();
    }
});


export default router;
