<template>
  <v-app>
    <v-main>
      <div :id="this.$route.name">
        <router-view />
      </div>
    </v-main>
  </v-app>
</template>

<script>
export default {};
</script>

<style>
  body {
    font-family: 'YourFontFamily', sans-serif;
  }

</style>